@import './scss/base/colors.scss';
@import './scss/typography.scss';
@import './scss/abstracts/variables.scss';
@import './scss/animations';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

body,
html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: unset !important;
  font-feature-settings: unset !important;
  font-family: 'Inter', sans-serif !important;
}

.big-title {
  font-size: 30px;
}

.base-title {
  font-size: 18px;
}

.tex-sm {
  font-size: 0.875rem;
}

.big-title,
.base-title {
  font-weight: 600;
}

.search-input-wrapper .form-control {
  box-shadow: none !important;
  border-color: transparent !important;
}

.cursor {
  cursor: pointer;
}

.nav {
  @include mobile {
    flex-wrap: nowrap;
    overflow: scroll;
    transition-duration: 300ms;

    .nav-item > .nav-link {
      width: max-content;
    }
  }
}

.sub-analytics__holder {
  display: flex;
  gap: 1rem;

  @include mobile {
    gap: 0.75rem;
    flex-wrap: wrap;
  }

  .sub-analytics__stats {
    width: 25%;
    @include mobile {
      width: 47.5%;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:focus,
.pin-field:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #242628 !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #24262833 !important;
}

.modal-backdrop {
  background-color: #1c1917 !important;
  --bs-backdrop-opacity: 0.6 !important;
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

.react-datepicker-popper {
  z-index: 10;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.user-select-none {
  user-select: none;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrDefinedRangesWrapper {
  width: 140px !important;
}

.rdrInputRanges {
  display: none;
}

::placeholder {
  color: #606060 !important;
  opacity: 0.4 !important;
}

:-ms-input-placeholder {
  color: #606060 !important;
}

::-ms-input-placeholder {
  color: #606060 !important;
}

.save-btn {
  background: #d28b28;
  color: #fff;
  padding: 5px 30px;
  border-radius: 30px;
  cursor: pointer;
}

.new-tab {
  font-size: 0.875rem !important;
  line-height: 20px;
  font-weight: 500 !important;
  position: relative;
  color: #79716b !important;
  border: 2px solid transparent !important;
  padding-bottom: 8px !important;

  &.active {
    font-weight: 600 !important;
    color: #1c1917 !important;
    border-bottom: 2px solid #1c1917 !important;
  }

  &:hover {
    background-color: $hover-color;
    box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);
  }
}

.ant-segmented {
  padding: 3px !important;
  height: 36px;
  border-radius: 10px !important;
  background: #fafaf9 !important;
  border: 1px solid #f5f5f4;

  .ant-segmented-item-icon {
    font-weight: 500;
  }

  .ant-segmented-item {
    margin-bottom: 0;

    .ant-segmented-item-label {
      color: #79716b;
    }
  }

  .ant-segmented-item-selected {
    height: 28px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px 0px #1c19171a;

    .ant-segmented-item-icon {
      color: #1c1917;
    }

    .ant-segmented-item-label {
      color: #1c1917;
    }
  }
}

.primary {
  .switch-default.ant-switch-checked {
    background: #bfff0b !important;
  }
}

.switch-default.ant-switch-checked {
  background: #d28b28 !important;
}

.form-design {
  .ant-form-item-explain-error {
    color: red;
    font-size: 12px;
  }

  input {
    padding: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: flex-end;
      color: #586068 !important;
      opacity: 0.5;
      flex: none;
      order: 1;
      flex-grow: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #606060;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #606060;
    }
  }

  label {
    padding-top: 20px;
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 10px;
  }

  .ant-input-affix-wrapper {
    position: relative;

    span.ant-input-suffix {
      position: absolute;
      right: 22px;
      top: -2px;
      z-index: 1;
    }
  }
}

.form-control {
  position: relative;
}

.groupWrapper {
  position: relative;

  .view-password {
    position: absolute;
    top: 37px;
    right: 40px;
    cursor: pointer;
  }

  label {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
    color: $grey-500 !important;
  }

  .form-control,
  .form-select {
    font-weight: 400;
    font-size: 15px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    outline: 0;
    box-shadow: none;
    padding: 10px 12px;

    &:focus {
      background-color: #fff;
      border: 1px solid #e5e5e5;
      outline: 0;
      box-shadow: none;
    }
  }
}

.width-full {
  max-width: 100% !important;
}

.form-check-input {
  &:checked {
    background-color: #242628 !important;
    border-color: #242628 !important;
  }

  &:focus {
    border-color: #242628 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #2426282e !important;
  }
}

.page-title {
  font-size: 30px;
  line-height: 50px;
  font-weight: 600;
}

.border-full {
  border: 1px solid #e5e8e3;
}

.borderTop {
  border-top: 1px solid #e5e8e3 !important;
}

.borderRight {
  border-right: 1px solid #e5e8e3 !important;
}

.react-tiny-popover-container {
  z-index: 99999;
}

.innerWrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}

.tabinnerWrapper {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
}

.borderLeft {
  border-left: 1px solid #e5e8e3 !important;
}

.borderBottom {
  border-bottom: 1px solid #e5e8e3 !important;
}

.customButton,
.customButton:focus {
  max-width: 380px;
  width: 100%;
  background: #242628;
  border-radius: 10px;
  color: #f5f5f4 !important;
  transition: 0.3s;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  border: 0 !important;
}

.customButton:hover,
.customButton:active {
  background: #242628;
}

@media only screen and (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column;
  }
}

.form-control {
  transition: all;
  transition-duration: 300ms;
  border: 1px solid #e5e5e5 !important;

  &.has-error {
    border-color: #fda29b !important;
    box-shadow: 0px 0px 0px 4px #fee4e2 !important;
  }
}

.onboardingStepRow {
  justify-content: end;
}

.onboardingStepCol {
  display: flex;
  justify-content: space-evenly;
}

.onboardingStepDiv {
  margin-bottom: 35px;
}

.uploadFileBorder {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  // width: 380px;
  height: 100px;
  background: #f2f4f1;
  border: 2px dashed #e6e8e3;
  border-radius: 4px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
}

.uploadFileTextBorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;
  // width: 282px;
  height: 36px;
  opacity: 0.8;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.uploadFileText1 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #586068;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.uploadFileText2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #586068;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.uploadedFileText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #586068;
}

.uploadFileIcon {
  text-align: center;
  margin-bottom: 10px;
}

.uploadFileLabel {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 10px;
}

.information {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #586068;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 25px 0px 15px 0px;
}

.owner-information {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #586068;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -5px 0px 10px 0px;
}

textarea {
  resize: none !important;
}

.textareaLabel {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 10px 0;
  color: #79716b;
}

textarea::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #586068 !important;
  opacity: 0.5;
  flex: none;
  order: 1;
  flex-grow: 1;
}

textarea.form-control:disabled {
  background-color: #f5f5f4 !important;
  border: 1px solid #e7e5e4 !important;
}

.margin-top {
  margin-top: 25px !important;
}

.onboardingSavedHeader {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.36px;
  color: #000000;
  margin: 20px 0px 10px 0px;
}

.fileUploaded {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;

  // width: 380px;
  height: 40px;

  background: linear-gradient(180deg, #ffffff 0%, #f2f3f1 100%);
  /* gray/light */

  border: 1px solid #e5e5e5;
  border-radius: 5px;
  justify-content: space-between;
  margin-top: 10px;
}

.datepicker-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  display: flex;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  outline: none;
  // border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  // border: 1px solid #e5e5e5 !important;
}

.datepicker-form-control:focus {
  border-color: none !important;
  border: none !important;
  outline: none;
  box-shadow: none !important;
}

.datepicker-div {
  // flex-direction: row;
  overflow: hidden;
  align-items: center;
  padding: 3.5px 14px 3.5px 0px;
  gap: 8px;
  width: 100% !important;
  background: #ffffff;
  border: 1px solid #e6e8e3;
  border-radius: 6px;
  flex: none;
  display: flex;
  order: 0;
  align-self: stretch;
  margin: 0px;
  transition: all;
  transition-duration: 300ms;

  &.has-error {
    border-color: #fda29b !important;
    box-shadow: 0px 0px 0px 4px #fee4e2 !important;
  }
}

.left-clock {
  padding: 3.5px 0px 3.5px 14px !important;
}

.datepicker-div:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
}

.datepicker-div:hover {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
}

.datepicker-div:active {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
}

.css-1s2u09g-control {
  height: 50px !important;
  border: 1px solid #e6e8e3;
  border-radius: 4px;
  outline: none !important;
}

.css-1s2u09g-control:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.creatable-select {
  // border-color: #242628 !important;
  // box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.creatable-select:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.css-b62m3t-container {
  // border-color: #242628 !important;
  // box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.css-b62m3t-container:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.css-1s2u09g-control {
  // border-color: #242628 !important;
  // box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.css-1s2u09g-control:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: none !important;
}

.card-body {
  padding: 1rem 0.5rem !important;
}

.modal-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #586068;
  margin-bottom: 25px;
}

.viewReimbursementTile {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #586068;
}

.viewReimbursementValue {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.viewReimbursementStatusDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.delete-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #586068;
}

.delete-btn {
  justify-content: center !important;
  align-items: center !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  // width: 380px !important;
  height: 44px !important;
  background: #dc2626 !important;
  border-radius: 8px !important;
}

.approve-btn {
  justify-content: center !important;
  align-items: center !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  // width: 380px !important;
  height: 44px !important;
  background: #32cd32 !important;
  border-radius: 8px !important;
}

.cancel-delete {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #242628;
  cursor: pointer;
}

.PhoneInputInput {
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 1px solid #e5e5e5;
  padding-left: 20px;
}

.PhoneInputInput:focus {
  outline: none !important;
}

.PhoneInputDiv {
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  outline: 0;
  box-shadow: none;
  padding: 10px;
  border-radius: 6px;
}

.PhoneInputDiv:focus {
  border-color: #242628 !important;
  box-shadow: 0 0 0 0.25rem #24262821 !important;
  outline: 0 0 0 0.25rem #24262821 !important;
}

.allSetButton {
  padding: 18px 46px !important;
  gap: 12px;
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  border-radius: 8px !important;
  color: #000000 !important;
}

.verifying {
  background: #bfc6b9;
  border-radius: 1000px;
  align-items: center;
  padding: 3px 8px 1px;
  margin-right: 25px;
}

.bujeti-toast {
  min-width: 320px !important;
  width: max-content !important;
}

.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
  width: 0px;
}

.table-responsive::-webkit-scrollbar:horizontal {
  height: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.opacity-50 {
  opacity: 0.5;
}

.columnheader {
  .ant-checkbox-input {
    opacity: 1 !important;
  }
}

.truncated-text {
  // width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-checkbox-wrapper {
  margin-right: 2px !important;
  margin-left: 5px !important;

  &.colored {
    font-weight: 500;
    color: #79716b !important;

    .ant-checkbox-checked {
      &::after {
        border-color: #d28b28 !important;
      }

      .ant-checkbox-inner {
        border: 1px solid #d28b28 !important;
        background-color: #d28b28 !important;
        box-shadow: 0px 0px 0px 4px #f0f1ee !important;

        &:hover {
          border-color: #d28b28 !important;
        }
      }
    }
  }
}

// .ant-checkbox:hover .ant-checkbox-inner {
//   background-color: #f4e0c5 !important;
// }
.ant-checkbox {
  &:hover {
    transform: scale(1.05);
    transition-duration: 300ms;
  }

  &.ant-checkbox-checked {
    &:hover {
      .ant-checkbox-inner {
        background-color: #d28b28 !important;
      }
    }
  }

  &:hover {
    .ant-checkbox-inner {
      background-color: #f4e0c5 !important;
    }
  }
}

.ant-checkbox-inner {
  border-radius: 4px;
  border: 1px solid #d8d8d6 !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.ant-checkbox-wrapper:focus .ant-checkbox-inner {
  border-color: #d28b28 !important;
  box-shadow: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #d28b28 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d28b28 !important;
  border-color: #d28b28 !important;
  box-shadow: none !important;

  &:hover {
    border-color: #d28b28;
    background-color: #f9f9f9;
  }
}

.ant-tooltip {
  max-width: 330px !important;
}

.ant-tooltip.ant-tooltip-placement-top {
  &.balance-tip {
    top: 94px !important;
  }

  &.invoice-tip {
    top: 258px !important;
  }
}

.ant-tooltip-inner {
  font-weight: 500;
  line-height: 18px !important;
  background-color: #1c1917;
  border-radius: 6px !important;
  padding: 8px 12px !important;
  box-shadow: 0px 12px 16px -4px, 0px 4px 6px -2px #1c191708 !important;
}

.ant-tooltip-arrow {
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #1c1917;
    top: -5px;
  }
}

.tool-tip .ant-tooltip-arrow-content {
  border-top-color: white !important;
  /* Make the arrow white */
  --antd-arrow-background-color: white;
}

.Toastify__toast-container {
  min-width: 320px !important;
  width: auto !important;
  max-width: 500px !important;
}

@media (max-width: 768px) {
  .Toastify__toast-container {
    width: 100% !important;
  }
}

.align-center {
  align-items: center !important;
}

.font-semibold {
  font-weight: $font-semibold;
}

.font-medium {
  font-weight: $font-medium;
}

.text-xs {
  font-size: $text-xs;
}

.text-sm {
  font-size: $text-sm !important;
}

.text-lg {
  font-size: $text-lg !important;
}

.text-base {
  font-size: $text-base;
}

.text-ds-xs {
  font-size: $text-ds-xs;
}

.color-grey-600 {
  color: $grey-600;
}

.color-grey-900 {
  color: $grey-900 !important;
}

.color-grey-500 {
  color: $grey-500;
}

.w-full {
  width: 100%;
}

.details-amount {
  font-size: $text-ds-sm;
}

.padding-top-2 {
  padding-top: 18px;
}

.padding-top-base {
  padding-top: $size-base;
}

.padding-top-xxs {
  padding-top: 6px;
}

.divider {
  background-color: $grey-200;
  height: 1px;
  width: 100%;
  @include margin(24px, 0, 24px, 0);

  &.medium {
    margin: 14px 0;
  }
}

.receipt-asset-wrapper {
  display: flex;
  // align-items: center;
  gap: 8px;

  .receipt-asset {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey-100;
    background-color: $base-white;
    border-radius: 4px;
    width: 56px;
    height: 80px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Added bottom faded box shadow

    &.sm {
      border-radius: 16px;
      width: 56px;
      height: 80px;
    }

    &.lg {
      border-radius: 16px !important;
      border: 2px solid #f5f5f4 !important;
      width: 140px !important;
      height: 200px !important;
    }

    img {
      width: 44px;
      height: 70px;
      object-fit: cover;
      cursor: pointer;
      border-radius: 4px;

      &.lg {
        border-radius: 16px;
        width: 140px !important;
        height: 200px !important;
      }
    }
  }

  .pdf-small {
    img {
      height: 55px;
    }
  }
}

.app-layout {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: #fff;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .aside {
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 50;
    height: fit-content;
    flex: 0 1 auto !important;
    overflow-x: clip;
  }

  @media (min-width: 768px) {
    display: flex;
    height: 100vh;
  }

  .section {
    height: 100vh;
    flex: 1 1 0%;
    overflow-y: hidden;

    .main-content {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      min-height: 100vh;
      max-width: 110rem;
      padding: 0px 24px;
      @media screen and (max-width: 567px) {
        padding: 10px;
      }
    }
  }
}

.reduce-height {
  min-height: calc(100vh - 60px) !important;

  @media (min-width: 768px) {
    height: calc(100vh - 60px) !important;
  }

  .section {
    height: calc(100vh - 60px) !important;

    .main-content {
      min-height: calc(100vh - 60px) !important;
    }
  }
}

.bdr-t1 {
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-color: $grey-200;
}

.table-row {
  background-color: inherit;

  &:hover {
    td {
      background-color: $hover-color;
    }
  }
}

.batch-footer {
  .border-btn {
    background: transparent !important;
    border: 2px solid #f2f4f1 !important;
    box-shadow: none !important;
    color: #586068 !important;
    height: 42px !important;
    transition: transform 0.3s ease !important;
  }

  .confirm-btn {
    background: #242628 !important;
    border-radius: 10px !important;
    height: 44px !important;
    color: #fff !important;

    &.success {
      background-color: transparent !important;
      border: 2px solid #171721 !important;
      color: #171721 !important;

      &:hover {
        color: #fff !important;
        background-color: #171721 !important;
      }
    }

    &.failed {
      background-color: transparent !important;
      border: 1px solid #e6e8e3 !important;
      color: #171712 !important;

      &:hover {
        background-color: #f9f9fa !important;
      }
    }

    &.declined {
      background-color: transparent !important;
      border: 2px solid rgb(255, 54, 137) !important;
      color: rgb(167, 0, 69) !important;

      &:hover {
        background-color: rgb(255, 54, 137) !important;
      }
    }

    &.pending {
      background-color: transparent !important;
      border: 2px solid rgb(100, 255, 45) !important;
      color: rgb(43, 161, 0) !important;

      &:hover {
        background-color: rgb(100, 255, 45) !important;
      }
    }

    &.processing {
      background-color: transparent !important;
      border: 2px solid rgb(255, 234, 45) !important;
      color: rgb(161, 145, 0) !important;

      &:hover {
        background-color: rgb(255, 234, 45) !important;
      }
    }
  }

  .modal-footer {
    background-color: #fff;
    bottom: 0px !important;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding: 16px 32px;
    position: static !important;
    width: 520px;
    right: 0px;
    border-top: 1px solid #d7d3d0;

    .custom-button {
      height: 40px !important;
      padding: 10px !important;
      width: 87px;
      min-width: 100px;
      font-size: 14px !important;
    }

    .ghost-button {
      background-color: #fff !important;
      border: 1px solid #e1e4dd !important;
      box-shadow: 0 1px 2px rgba(22, 22, 21, 0.05) !important;
      color: #242628 !important;
    }

    .primary-button {
      height: 40px !important;
      width: max-content;
    }
  }
}

.batch-payment-confirm {
  padding: 16px;

  .heading-confirm {
    border-bottom: 1px solid #eaecf0;
    padding-bottom: 10px;

    h5 {
      font-weight: 600;
      font-size: 14px;
    }

    h6 {
      font-weight: 400;
      font-size: 14px;
    }

    .text-gray {
      color: #79716b;
    }
  }
}

.nowrap {
  white-space: nowrap;
}
.spaced-table thead th:last-child {
  width: 4.375rem !important;
}

/** Date Picker **/
.react-datepicker {
  background-color: white !important;
  box-shadow: 0px 20px 24px -4px #1c191714;
  border: 1px solid #f5f5f4 !important;
  font-family: 'Inter', sans-serif !important;
  min-width: 328px !important;
  padding: 20px;

  &.react-datepicker--time-only {
    min-width: unset !important;
  }

  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;

    .react-datepicker__header__dropdown--select {
      padding: 10px 0px;

      .react-datepicker__month-select,
      .react-datepicker__year-select {
        height: 44px;
        box-shadow: 0px 1px 2px 0px #1c19170d;
        border: 1px solid #d7d3d0;
        border-radius: 8px;
        padding: 0 12px;
        color: #1c1917;
        font-weight: 500;
        font-size: 1rem;

        &:focus {
          border: 1px solid #d7d3d0 !important;
        }
      }
    }
  }

  .react-datepicker__day-name {
    font-size: 0.875rem;
    font-weight: 500;
    color: #44403c;
    margin: 0.4rem;
  }

  .react-datepicker__month-dropdown-container--select {
    margin: 0 12px;
  }

  .react-datepicker__day {
    color: #101828 !important;
    font-size: 14px !important;
    line-height: 32px !important;
    width: 32px;
    margin: 4px;
  }

  .react-datepicker__day--disabled {
    color: #79716b !important;
    cursor: not-allowed;
  }

  .react-datepicker__day--selected {
    border-radius: 100%;
    background-color: #9edb08 !important;
    color: #ffffff !important;
    font-weight: normal !important ;
  }

  .react-datepicker__current-month {
    padding: 10px 0 !important;
    font-size: 1rem !important;
    color: #44403c;
  }
}

.roles-description {
  display: block;
  width: 420px;
}

.lg-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  position: relative;

  .lg-modal-body {
    background: #ffffff;
    box-shadow: 0 12px 40px rgba(16, 24, 64, 0.24);
    border-radius: 8px;
    width: 500px;
    padding: 32px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .cancel-icon {
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
    }

    .back-icon {
      position: absolute;
      top: 32px;
      left: 32px;
      cursor: pointer;
    }

    .delete-icon {
      width: 48px;
      height: 48px;
      // background: #fee4e2;
      // border: 8px solid #fef3f2;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 20px;
      line-height: 35px;
      margin: 0 0 5px;
      text-align: center;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      text-align: center;
    }

    .remove-btn {
      background: #dc2626 !important;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06) !important;
      border-radius: 10px !important;
      height: 40px !important;
      color: #fff !important;
    }

    .confirm-btn {
      background: #242628 !important;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06) !important;
      border-radius: 10px !important;
      height: 40px !important;
      color: #fff !important;

      .success {
        background-color: transparent !important;
        border: 2px solid #171721 !important;
        color: #171721 !important;
      }

      .retry {
        background-color: transparent !important;
        border: 2px solid #dc2626 !important;
        color: #dc2626 !important;
      }

      .pending {
        background-color: transparent !important;
        border: 2px solid #bfff0b !important;
        color: #bfff0b !important;
      }
    }

    .confirm-btn:hover,
    .confirm-btn:active {
      color: #bfff0b !important;
      background: #242628 !important;
    }

    .cancel-btn {
      background: transparent !important;
      box-shadow: none !important;
      color: #171721 !important;
      height: 50px !important;
      padding-bottom: 0 !important;
    }

    .border-btn {
      background: transparent !important;
      border: 2px solid #f2f4f1 !important;
      box-shadow: none !important;
      color: #586068 !important;
      height: 42px !important;
    }
  }
}

.kaizer {
  @media only screen and (max-width: 320px) {
    width: auto !important;
  }
  @media only screen and (max-width: 480px) {
    width: auto !important;
  }
}

.no-mobile-tabs {
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.quick-actions,
.onDesktop,
.subText {
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.onMobile {
  @media screen and (min-width: 480px) {
    display: none !important;
  }
}

.home-actions {
  border-bottom: 1px solid #e8e8e7;
  padding-bottom: 1rem;
  @media screen and (max-width: 480px) {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }
}

@media screen and (max-width: 480px) {
  .d-xs-flex {
    display: flex !important;
  }
  .onDesktop.d-flex {
    display: none !important;
  }
  div.ms-auto.my-2 {
    margin-left: initial !important;
  }
  .budgets-card-wrapper {
    margin: 0px 0px !important;

    .col-md-6 {
      padding: 0px 0px !important;
    }
  }

  div.d-flex.flex-wrap {
    display: block !important;

    .budgets-amount,
    .teams-amount {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-bottom: 0px;
      h3 {
        font-size: 15px;
      }
    }
  }

  .small-text {
    margin: 10px 0px;
  }

  .emptystate-wrapper .emptystate-body {
    padding: 20px;

    p {
      display: contents;
    }
  }
}

.btn {
  &.black {
    border-radius: 8px !important;
    border: 1px solid #000;
    background: #000 !important;
    display: flex;
    min-width: fit-content !important;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &.danger {
    border-radius: 8px !important;
    border: 1px solid #d92d20;
    background: #d92d20 !important;
    display: flex;
    min-width: fit-content !important;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &.border {
    color: #57534e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid #d7d3d0;
    background: #fff;
    display: flex;
    min-width: fit-content !important;
    justify-content: center;
    align-items: center;
  }

  &.w-full {
    width: 100%;
  }

  &.w-fit {
    width: fit-content;
  }

  &:hover.black {
    background: #292524 !important;
  }

  &:hover.border {
    background: #f5f5f4;
  }

  &.sm {
    font-size: 14px;
    padding: 10px 16px;
  }

  &.xs {
    font-size: 14px;
    padding: 6px 16px;
  }

  &.xxs {
    font-size: 14px;
    padding: 6px 8px;
  }
}

.form-group {
  .form-label {
    font-size: 0.875rem;
    color: #44403c !important;
    font-weight: 500;
    cursor: pointer;
    margin: unset !important;
    margin-bottom: 0.5rem !important;

    &.custom {
      margin-bottom: 4px !important;
    }
  }

  input {
    height: 40px;
    font-size: 0.875rem !important;
    box-shadow: 0px 1px 2px 0px #1c19170d !important;
    border-radius: 8px;

    &:disabled {
      background: #f5f5f4;
      color: #d7d3d0;
      box-shadow: none;
    }

    &.amount {
      padding-right: 108px;
      padding-left: 26px !important;

      &.approval {
        border-radius: 12px;
      }
    }
  }

  textarea {
    box-shadow: 0px 1px 2px 0px #1c19170d;
    border-radius: 8px;
    color: #1c1917;
    font-size: 0.875rem;
    font-weight: 400 !important;
    line-height: 28px;

    &::placeholder {
      font-size: 0.875rem;
    }

    &:disabled {
      background: #ffffff;
    }
  }

  &.custom {
    input {
      height: unset !important;
    }
  }

  .dropdown-select__control {
    border-radius: 8px !important;
    min-height: unset !important;
    min-height: 40px;
    padding: 1px 3px !important;
    cursor: pointer;

    &.hasError {
      border-color: #fda29b !important;
      box-shadow: 0px 0px 0px 4px #fee4e2 !important;
    }

    .dropdown-select__input {
      min-width: max-content !important;
      font-size: 0.875rem !important;
      box-shadow: none !important;
      border-radius: 0px !important;
    }

    &.dropdown-select__control--is-focused {
      box-shadow: 0 0 0 0.25rem rgba(36, 38, 40, 0.1294117647) !important;
    }

    .dropdown-select__indicator {
      transition: transform 0.3s ease-in-out;
    }

    &.dropdown-select__control--menu-is-open .dropdown-select__indicator {
      transform: rotateX(180deg);
    }

    .dropdown-select__single-value {
      color: #1c1917 !important;
      font-weight: 500;
      font-size: 0.875rem;

      &.is-placeholder {
        color: #a9a29d !important;
      }
    }

    .dropdown-select__placeholder {
      color: #a9a29d;
      font-size: 0.875rem;
    }
  }
}

.date-select {
  height: 40px;
  box-shadow: 0px 1px 2px 0px #1c19170d;
  border-radius: 8px;

  &.disabled {
    box-shadow: none;
  }

  input {
    color: #1c1917;
    height: auto;
    font-size: 0.875rem;
    box-shadow: none !important;
    border-radius: 0px !important;
  }
}

.component_page-section {
  max-width: 894px;
  padding: 32px 0px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 32px 16px;
  }

  .elements-section {
    .back-btn {
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      div {
        color: #d28b28;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .section-title {
      padding: 20px 0 8px 0;
      border-bottom: 1px solid #e7e5e4;

      h1 {
        color: #1c1917;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      p {
        color: #79716b;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 150% */
      }

      @media (max-width: 768px) {
        h1 {
          font-size: 18px !important;
          margin: 0px !important;
        }

        p {
          margin: 0px !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

// quick action
.alert_action-banner {
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  padding: 24px;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: #f5f5f4;
  box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);
  .banner-text {
    h1 {
      color: #1c1917;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    p {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  @media (max-width: 1224px) {
    .image-banner {
      display: none;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 12px;

    button {
      color: #d28b28;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    a {
      &.btn {
        color: #d28b28 !important;
      }
    }

    .btn {
      padding: 0px !important;
      width: fit-content !important;
      background: transparent !important;
      border: none !important;
    }
  }
}
.mmt-10 {
  margin-top: -10px !important;
}

.modal_visible__nYsri .modal_modal__q21-u {
  @media screen and (max-width: 480px) {
    padding: 0 !important;
  }
}

.condition-cta {
  display: flex;
  align-items: center;
  padding: 2px 9px;
  max-width: fit-content !important;
  height: 24px !important;
  font-size: 14px;
  cursor: pointer;
  & > span {
    color: #57534e;
  }
}

.connector-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  .connector {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    width: 58px;
    color: #79716b;
  }
  .connector-wrapper-item {
    display: flex;
    justify-content: center;
  }
}

.connector-wrapper-item::before {
  content: '';
  display: flex;
  height: 19px;
  width: 1px;
  background-color: #d9ddd5;
  position: absolute;
  top: 10px;
}

.connector-wrapper-item::after {
  content: '';
  display: flex;
  height: 19px;
  width: 1px;
  background-color: #d9ddd5;
  position: absolute;
  bottom: 10px;
}

.overlay-trigger {
  z-index: 9999999999 !important;
}

#custom-toast {
  z-index: 99999999999 !important;
  position: relative !important;
}

$black: #333333;
$blue: #686de0;
$gray: #d3d3d3;
$green: #6ab04c;
$red: #dc3545;
$white: #ffffff;

.pin-field-container {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  margin: 4rem 0;
}

.pin-label {
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 5px;
}

.pin-field {
  border: 1px solid $gray;
  font-size: 2rem;
  height: 3.75rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: color, border, box-shadow, transform;
  width: 3.75rem;
  margin-right: 20px;
  border-radius: 0.5rem;

  &:last-of-type {
    margin-right: 0px;
  }

  &:invalid {
    animation: shake 5 linear 75ms;
    border-color: $red;
    box-shadow: 0 0 0.25rem rgba($red, 0.5);

    &:focus {
      border-color: $red !important;
      box-shadow: 0 0 0.25rem rgba($red, 0.5) !important;
    }
  }

  &.complete {
    border-color: $green;
    color: $green;

    &[disabled] {
      background: rgba($green, 0.1);
      cursor: not-allowed;
      opacity: 0.5;
    }

    & + .pin-field {
      border-left-color: $green;
    }
  }

  @media screen and (max-width: 480px) {
    margin-right: 10px;
    height: 2.5rem;
    width: 2.5rem;
  }

  &:first-of-type {
    margin-left: 15px;
  }
}

@keyframes shake {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(5%);
  }
}
